import React from 'react'
import { graphql } from 'gatsby'
// import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
// import Img from 'gatsby-image/withIEPolyfill'
// import { css } from '@emotion/core'

import Layout from 'templates/layout'
import SEO from 'components/seo'
// import Modal from 'components/modal'
import Button from 'components/atoms/button'
import Section from 'components/organisms/section'

export default ({
  data: {
    markdownRemark: {
      frontmatter: { video_url, items, height, title, photo },
    },
  },
}) => (
  <Layout>
    <SEO
      title={`スタイリング ${title}`}
      description="sieniの提案するスタイリングを紹介しています。"
    />
    <div className="t-styles">
      <Section title="Styling" sub="スタイリング">
        <div className="t-styles__player">
          {video_url ? (
            <video playsinline autoPlay muted src={video_url} />
          ) : (
            <img alt={title} src={photo.publicURL} />
          )}
        </div>
        <div className="t-styles__descriptions">
          {items &&
            items.map(
              ({ brand, name, size, category, color, online_shop_url }) => (
                <p>
                  <span>
                    {category} : {brand}
                  </span>
                  {size && <span>サイズ{size}</span>}
                  {color && <span>{color}</span>}
                  {name && <span>{name}</span>}
                  {online_shop_url && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={online_shop_url}
                    >
                      > STORE
                    </a>
                  )}
                </p>
              )
            )}
          <dl>
            <dt>モデル身長</dt>
            <dd>{height}cm</dd>
          </dl>
        </div>
        <div className="t-styles__footer">
          <Button internal="/styles" thin>
            back
          </Button>
        </div>
      </Section>
    </div>
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        photo {
          publicURL
        }
        status
        title
        items {
          brand
          name
          size
          category
          color
          online_shop_url
        }
        height
      }
    }
  }
`
